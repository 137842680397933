import Pagination from 'rc-pagination/lib/locale/ca_ES';
import DatePicker from '../date-picker/locale/ca_ES';
import TimePicker from '../time-picker/locale/ca_ES';
import Calendar from '../calendar/locale/ca_ES';
const localeValues = {
    locale: 'ca',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Filtrar Menu',
        filterConfirm: 'OK',
        filterReset: 'Restablir',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Cancel·lar',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Cancel·lar',
    },
    Transfer: {
        searchPlaceholder: 'Cercar aquí',
        itemUnit: 'item',
        itemsUnit: 'items',
    },
    Upload: {
        uploading: 'Carregant...',
        removeFile: 'Elimina el fitxer',
        uploadError: 'Error de càrrega',
        previewFile: 'Vista prèvia del fitxer',
        downloadFile: "Descarrega l'arxiu",
    },
    Empty: {
        description: 'Sense dades',
    },
};
export default localeValues;
