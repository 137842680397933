(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "moment"], factory);
	else if(typeof exports === 'object')
		exports["antd"] = factory(require("react"), require("react-dom"), require("moment"));
	else
		root["antd"] = factory(root["React"], root["ReactDOM"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__62__) {
return 