import * as React from 'react';
import RcTextArea from 'rc-textarea';
import omit from 'omit.js';
import ClearableLabeledInput from './ClearableLabeledInput';
import { ConfigConsumer } from '../config-provider';
import { fixControlledValue, resolveOnChange } from './Input';
class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.focus = () => {
            this.resizableTextArea.textArea.focus();
        };
        this.saveTextArea = (textarea) => {
            this.resizableTextArea = textarea === null || textarea === void 0 ? void 0 : textarea.resizableTextArea;
        };
        this.saveClearableInput = (clearableInput) => {
            this.clearableInput = clearableInput;
        };
        this.handleChange = (e) => {
            this.setValue(e.target.value);
            resolveOnChange(this.resizableTextArea.textArea, e, this.props.onChange);
        };
        this.handleReset = (e) => {
            this.setValue('', () => {
                this.focus();
            });
            resolveOnChange(this.resizableTextArea.textArea, e, this.props.onChange);
        };
        this.renderTextArea = (prefixCls) => {
            return (<RcTextArea {...omit(this.props, ['allowClear'])} prefixCls={prefixCls} onChange={this.handleChange} ref={this.saveTextArea}/>);
        };
        this.renderComponent = ({ getPrefixCls, direction }) => {
            const { value } = this.state;
            const { prefixCls: customizePrefixCls } = this.props;
            const prefixCls = getPrefixCls('input', customizePrefixCls);
            return (<ClearableLabeledInput {...this.props} prefixCls={prefixCls} direction={direction} inputType="text" value={fixControlledValue(value)} element={this.renderTextArea(prefixCls)} handleReset={this.handleReset} ref={this.saveClearableInput} triggerFocus={this.focus}/>);
        };
        const value = typeof props.value === 'undefined' ? props.defaultValue : props.value;
        this.state = {
            value,
        };
    }
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return {
                value: nextProps.value,
            };
        }
        return null;
    }
    setValue(value, callback) {
        if (!('value' in this.props)) {
            this.setState({ value }, callback);
        }
    }
    blur() {
        this.resizableTextArea.textArea.blur();
    }
    render() {
        return <ConfigConsumer>{this.renderComponent}</ConfigConsumer>;
    }
}
export default TextArea;
